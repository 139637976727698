<template>
  <el-dialog
    title="回饋金"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <BaseElFormItem label="折抵方式" prop="mode">
        <BaseElRadioGroup v-model="formData.mode" @change="useModeChange">
          <BaseElRadio label="all">全部回饋金</BaseElRadio>
          <BaseElRadio label="part">部分回饋金</BaseElRadio>
        </BaseElRadioGroup>
      </BaseElFormItem>
      <BaseElFormItem label="折抵金額" prop="use">
        <BaseElInput v-model="formData.use" :disabled="formData.mode === 'all'" />
      </BaseElFormItem>
      <BaseElFormItem label="餘額">
        {{ usedLeft }}
      </BaseElFormItem>
    </BaseElForm>

    <div slot="footer" class="dialog-footer">
      <div class>
        <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
        <BaseElButton type="primary" @click="handleConfirm">
          確認
        </BaseElButton>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { reactive, computed, ref, onMounted } from 'vue'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation'
import formUtils from '@/utils/form'

const props = defineProps({
  balance: {
    type: [String, Number],
    default: 0,
  },
  totalPrice: {
    type: [String, Number],
    default: 0,
  },
})
const emit = defineEmits(['confirm'])
const formRef = ref(null)
const formData = reactive({
  mode: 'all',
  use: null,
})
const formRules = computed(() => {
  let max = props.balance
  if (props.balance > props.totalPrice) max = props.totalPrice
  return {
    mode: [noEmptyRules()],
    use: [noEmptyRules(), isDigitRules(), rangeRules(0, max)],
  }
})

const usedLeft = computed(() => props.balance - formData.use)

const handleConfirm = async () => {
  if (!await formUtils.checkForm(formRef.value)) return
  emit('confirm', formData.use)
  console.log('pass')
}

const useModeChange = (mode) => {
  if (mode === 'all') {
    if (props.balance > props.totalPrice) formData.use = props.totalPrice
    else formData.use = props.balance
  }
}

onMounted(() => {
  if (props.balance > props.totalPrice) formData.use = props.totalPrice
  else formData.use = props.balance
})
</script>

<style scoped lang="postcss">

</style>
